import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easings";

const StyledWrapper = styled.a<{ $iconSize?: string }>`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0;
  }

  svg {
    font-size: ${({ $iconSize }) => $iconSize || "2.4rem"};
    width: auto;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 580px) {
    font-size: 1.6rem;
  }
`;

const StyledIconWrapper = styled.div`
  width: 28px;
  display: flex;
  justify-content: center;
  margin-right: 18px;

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 580px) {
    margin-bottom: 10px;
  }
`;

const StyledContentWrapper = styled.div`
  position: relative;

  ::before {
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.foreground};
    position: absolute;
    content: "";
    bottom: -4px;
    transition: transform 0.5s ${cssEaseOutExpo};
    transform: scaleX(0);
  }

  ${StyledWrapper}:hover &::before {
    transform: scaleX(1);
  }

  @media (max-width: 580px) {
    text-align: center;
  }
`;

const ContactItem: FC<Props> = ({ icon, href, content, iconSize }) => (
  <StyledWrapper href={href} $iconSize={iconSize}>
    <StyledIconWrapper>
      <Icon icon={icon} />
    </StyledIconWrapper>
    <StyledContentWrapper>{content}</StyledContentWrapper>
  </StyledWrapper>
);

interface Props {
  icon: any;
  iconSize?: string;
  href: string;
  content: string;
}

export default ContactItem;
