export const instagramIcon = {
  body: `
  <path d="M18.6748 23.9873L5.34851 24C2.41673 24.0027 0.0159938 21.6066 0.0126622 18.6748L2.22137e-06 5.34851C-0.00266304 2.41673 2.39341 0.0159938 5.32519 0.0126622L18.6515 2.22137e-06C21.5833 -0.00266304 23.984 2.39341 23.9873 5.32519L24 18.6515C24.0033 21.5839 21.6066 23.9847 18.6748 23.9873Z" fill="url(#paint0_radial_20_80)"/>
  <path d="M18.6748 23.9873L5.34851 24C2.41673 24.0027 0.0159938 21.6066 0.0126622 18.6748L2.22137e-06 5.34851C-0.00266304 2.41673 2.39341 0.0159938 5.32519 0.0126622L18.6515 2.22137e-06C21.5833 -0.00266304 23.984 2.39341 23.9873 5.32519L24 18.6515C24.0033 21.5839 21.6066 23.9847 18.6748 23.9873Z" fill="url(#paint1_radial_20_80)"/>
  <path d="M12.0001 16.6648C9.42883 16.6648 7.33594 14.5726 7.33594 12.0006C7.33594 9.42865 9.42883 7.33643 12.0001 7.33643C14.5715 7.33643 16.6643 9.42865 16.6643 12.0006C16.6643 14.5726 14.5715 16.6648 12.0001 16.6648ZM12.0001 8.66906C10.1631 8.66906 8.66857 10.1636 8.66857 12.0006C8.66857 13.8377 10.1631 15.3322 12.0001 15.3322C13.8372 15.3322 15.3317 13.8377 15.3317 12.0006C15.3317 10.1636 13.8372 8.66906 12.0001 8.66906Z" fill="white"/>
  <path d="M16.9975 8.00285C17.5495 8.00285 17.997 7.55537 17.997 7.00338C17.997 6.45139 17.5495 6.00391 16.9975 6.00391C16.4455 6.00391 15.998 6.45139 15.998 7.00338C15.998 7.55537 16.4455 8.00285 16.9975 8.00285Z" fill="white"/>
  <path d="M15.9979 20.6626H8.00209C5.43079 20.6626 3.33789 18.5703 3.33789 15.9984V8.00258C3.33789 5.43061 5.43079 3.33838 8.00209 3.33838H15.9979C18.5692 3.33838 20.6621 5.43061 20.6621 8.00258V15.9984C20.6621 18.5703 18.5692 20.6626 15.9979 20.6626ZM8.00209 4.67101C6.16506 4.67101 4.67052 6.16555 4.67052 8.00258V15.9984C4.67052 17.8354 6.16506 19.3299 8.00209 19.3299H15.9979C17.8349 19.3299 19.3294 17.8354 19.3294 15.9984V8.00258C19.3294 6.16555 17.8349 4.67101 15.9979 4.67101H8.00209Z" fill="white"/>
  <defs>
  <radialGradient id="paint0_radial_20_80" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.92196 24.0173) scale(29.9169)">
  <stop stop-color="#FFDD55"/>
  <stop offset="0.328" stop-color="#FF543F"/>
  <stop offset="0.348" stop-color="#FC5245"/>
  <stop offset="0.504" stop-color="#E64771"/>
  <stop offset="0.643" stop-color="#D53E91"/>
  <stop offset="0.761" stop-color="#CC39A4"/>
  <stop offset="0.841" stop-color="#C837AB"/>
  </radialGradient>
  <radialGradient id="paint1_radial_20_80" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.86196 -0.299638) scale(19.8648 13.2359)">
  <stop stop-color="#4168C9"/>
  <stop offset="0.999" stop-color="#4168C9" stop-opacity="0"/>
  </radialGradient>
  </defs>
`,
  width: 24,
  height: 24,
};
