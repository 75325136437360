import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "@theme/GlobalStyles";
import { theme } from "@theme/theme";
import Navigation from "@components/molecules/Navigation/Navigation";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useLocation } from "react-use";
import "locomotive-scroll/dist/locomotive-scroll.css";
import Footer from "@components/molecules/Footer/Footer";
import Loader from "@components/atoms/Loader/Loader";

const BasicTemplate: FC<Props> = ({
  children,
  renderHero: Hero,
  title,
  subpageTitle,
  description,
  ogImage,
}) => {
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const container = useRef();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);

    const setVh = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight / 100}px`
      );
    };
    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  const readyTitle = `${subpageTitle ? `${subpageTitle} | ` : ""}${title}`;

  return (
    <>
      <Helmet>
        <title>{readyTitle}</title>
        <html lang="pl" />

        <meta name="description" content={description} />

        <meta property="og:title" content={readyTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Loader isLoading={isLoading} />
        <LocomotiveScrollProvider
          options={{ smooth: true, lerp: 0.08 }}
          onLocationChange={(scroll: any) =>
            scroll.scrollTo(0, { duration: 0, disableLerp: true })
          }
          location={pathname}
          watch={[]}
          containerRef={container}
        >
          <div data-scroll-container="" ref={container} id="#scroll-wrapper">
            <header>
              <Navigation />
              {Hero && <Hero />}
            </header>
            <main>{children}</main>
            <Footer />
          </div>
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
};

interface Props {
  title?: string;
  subpageTitle?: string;
  renderHero?: FC;
  description: string;
  ogImage: string;
}

export default BasicTemplate;
