import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "@iconify/react";
import loadingIcon from "@iconify/icons-icon-park-outline/loading-four";
import { AnimatePresence, motion } from "framer-motion";

const StyledWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ theme }) => theme.background};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
`;

const loadingAnimation = keyframes`
  from {
    transform: rotate(0);
  }
  
  to {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 4rem;
  animation: ${loadingAnimation} 1s linear infinite;
`;

const Loader: FC<Props> = ({ isLoading }) => (
  <AnimatePresence>
    {isLoading && (
      <StyledWrapper initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <StyledIcon icon={loadingIcon} />
      </StyledWrapper>
    )}
  </AnimatePresence>
);

interface Props {
  isLoading: boolean;
}

export default Loader;
