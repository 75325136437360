import React, { FC, HTMLProps, MouseEventHandler } from "react";
import styled, { css, keyframes } from "styled-components";
import { darken } from "polished";
import { useButtonEffects, rippleAnimation } from "@rendpro/core-ui";
import { cssEaseOutExpo } from "@theme/easings";
import { Icon } from "@iconify/react";
import loadingIcon from "@iconify/icons-icon-park-outline/loading-four";
import successfulIcon from "@iconify/icons-icon-park-outline/check";

const StyledButton = styled.button<{ $isDisable: boolean }>`
  padding: 15px 30px;
  border: 0;
  background: ${({ theme }) => theme.primary};
  cursor: pointer;
  color: ${({ theme }) => theme.foreground};
  font-weight: 600;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background 0.5s ${cssEaseOutExpo};

  :hover,
  :active {
    background: ${({ theme }) => darken(0.1, theme.primary)};
  }

  :active {
    transform: scale(0.9);
  }

  .ripple {
    width: 120px;
    height: 120px;
    position: absolute;
    background: ${({ theme }) => theme.foreground};
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 220px;
      height: 220px;
    }
  }

  ${({ $isDisable }) =>
    $isDisable &&
    css`
      cursor: default;

      :hover,
      :active {
        background: ${({ theme }) => theme.primary};
      }

      :active {
        transform: scale(1);
      }
    `}
`;

const StyledInnerWrapper = styled.div<{ $isDisable: boolean }>`
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  ${({ $isDisable }) =>
    $isDisable &&
    css`
      opacity: 0;
      transform: scale(0.6);
      pointer-events: none;
    `}
`;

export const loadingAnimation = keyframes`
  0% {
    transform: rotate(0) translate(-50%, -50%);
  }
  
  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
`;

const StyledIcon = styled(Icon)`
  stroke: #fff !important;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%) !important;
  z-index: 10;
  display: block;
  transform-origin: top left;
  font-size: 2.4rem;
`;

const StyledLoadingIcon = styled(StyledIcon)`
  animation: 1s ${loadingAnimation} linear infinite;
`;

const Button: FC<Props> = ({
  children,
  onMouseDown: onMouseDownProp,
  isLoading,
  isSuccessful,
  disabled,
  ...props
}) => {
  const { onMouseDown } = useButtonEffects({ rippleWidth: 220 });

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (e) => {
    onMouseDown(e);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onMouseDownProp && onMouseDownProp(e);
  };

  return (
    <StyledButton
      onMouseDown={handleMouseDown}
      $isDisable={isLoading || isSuccessful || disabled}
      disabled={disabled}
      {...(props as any)}
    >
      <StyledInnerWrapper $isDisable={isLoading || isSuccessful}>
        {children}
      </StyledInnerWrapper>
      {isLoading && <StyledLoadingIcon icon={loadingIcon} />}
      {isSuccessful && <StyledIcon icon={successfulIcon} />}
    </StyledButton>
  );
};

interface Props extends HTMLProps<HTMLButtonElement> {
  as?: any;
  to?: string;
  isLoading?: boolean;
  isSuccessful?: boolean;
}

export default Button;
