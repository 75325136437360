import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

export default createGlobalStyle`
    ${normalize}

    *, *::before, *::after {
        box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Poppins', sans-serif;
    }

    html {
        font-size: 10px;
    }


    html.has-scroll-smooth {
      overflow: hidden;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    body {
      font-size: 1.6rem;
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.foreground};
      font-family: 'Poppins', sans-serif;
    }

    #scroll-wrapper {
      width: 100%;
      min-height: 100vh;
      padding-bottom: 200px;
    }

    .c-scrollbar {
      z-index: 999999;
    }

    .c-scrollbar_thumb {
      background: #000;
    }
`;
