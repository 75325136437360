export const markerIcon = {
  body: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.5C8.68465 2.5 3.75 7.43469 3.75 13.7501C3.75 17.1249 5.99703 21.1453 10.4099 25.9205C10.5214 26.0412 10.6376 26.1574 10.7582 26.2689C13.2933 28.6115 17.2475 28.4556 19.5902 25.9205L19.9839 25.4901C24.1363 20.9019 26.25 17.0236 26.2501 13.7501C26.2501 7.43471 21.3154 2.5 15 2.5ZM15 5C19.9347 5 23.7501 8.81543 23.7501 13.7501C23.75 16.2561 21.9046 19.6421 18.1347 23.8077L17.7498 24.2285C16.3485 25.7448 13.976 25.8384 12.455 24.4328C12.3826 24.3659 12.3128 24.2962 12.2459 24.2238C8.22158 19.869 6.25001 16.3414 6.25001 13.7501C6.25001 8.8154 10.0654 5 15 5Z" fill="currentColor"/>
    <g opacity="0.5">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8.75C12.2386 8.75 10 10.9886 10 13.75C10 16.5114 12.2386 18.75 15 18.75C17.7614 18.75 20 16.5114 20 13.75C20 10.9886 17.7614 8.75 15 8.75ZM15 11.25C16.3807 11.25 17.5 12.3693 17.5 13.75C17.5 15.1307 16.3807 16.25 15 16.25C13.6193 16.25 12.5 15.1307 12.5 13.75C12.5 12.3693 13.6193 11.25 15 11.25Z" fill="currentColor"/>
    </g>
  `,
  width: 30,
  height: 30,
};
