export const mailIcon = {
  body: `
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 3.0081e-06H7C3.13401 3.0081e-06 0 3.13401 0 7V18.2C0 22.066 3.13401 25.2 7 25.2H20.9886L21.2922 25.1933C25.0162 25.0312 27.9861 21.9639 27.9915 18.202L27.9958 7.0084L28 6.99716C28 3.13041 24.8649 -0.00354412 21 3.0081e-06ZM21.0006 2.79983L21.2473 2.80674C23.3694 2.92786 25.0692 4.62708 25.1922 6.7502L25.1965 6.99912L25.1908 18.1991C25.1875 20.5176 23.3064 22.3977 20.9867 22.3998L6.99932 22.3998C4.67972 22.3998 2.79932 20.5194 2.79932 18.1998V6.99983C2.79932 4.68023 4.67972 2.79983 6.99932 2.79983L21.0006 2.79983Z" fill="currentColor"/>
  <g opacity="0.5">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4081 7.40956C7.91277 6.90488 8.7069 6.86606 9.25611 7.2931L9.388 7.40956L11.0282 9.04976C12.6028 10.6244 15.1166 10.6873 16.7663 9.23872L16.9679 9.04976L18.6081 7.40956C19.1548 6.86283 20.0413 6.86283 20.588 7.40956C21.0927 7.91424 21.1315 8.70836 20.7045 9.25757L20.588 9.38946L18.9478 11.0297C16.297 13.6805 12.049 13.7608 9.3014 11.2706L9.0483 11.0297L7.4081 9.38946C6.86136 8.84273 6.86136 7.9563 7.4081 7.40956Z" fill="currentColor"/>
  </g>
`,
  width: 28,
  height: 26,
};
