export const phoneIcon = {
  body: `
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8571 1.36499e-06H7.14286C3.19797 1.36499e-06 0 3.19797 0 7.14286V18.5714C0 22.5163 3.19797 25.7143 7.14286 25.7143H12.8494L13.1592 25.7075C16.9596 25.5431 19.9906 22.4123 19.9942 18.5728L20 7.14093C20 3.19552 16.8013 -0.00241193 12.8571 1.36499e-06ZM12.8573 2.85697L13.1091 2.86409C15.2748 2.98833 17.0094 4.7225 17.1349 6.88893L17.1402 7.1422L17.1364 18.5708C17.1341 20.9369 15.2149 22.8555 12.8478 22.857H7.14216C4.77522 22.857 2.85645 20.9382 2.85645 18.5713V7.14268C2.85645 4.77575 4.77522 2.85697 7.14216 2.85697L12.8573 2.85697Z" fill="currentColor"/>
  <g opacity="0.5">
  <ellipse cx="10.0008" cy="18.5716" rx="1.42857" ry="1.42857" fill="currentColor"/>
  </g>
`,
  width: 20,
  height: 26,
};
