import React from "react";
import styled from "styled-components";
import Logo from "@assets/svg/logo_secondary.svg";
import { NAVIGATION_ITEMS } from "@config/main";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";

const StyledWrapper = styled.footer`
  width: 90%;
  max-width: 1330px;
  margin: auto;
  padding: 50px 0;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-of-type {
    margin-top: 30px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    :last-of-type {
      margin-top: 50px;
    }
  }
`;

const StyledLogo = styled(Logo)`
  height: 70px;
  width: auto;
  margin-right: 50px;

  @media (max-width: 1024px) {
    margin-right: 0;
    order: 1;
    margin-bottom: 50px;
  }

  @media (max-width: 580px) {
    width: 100%;
    max-width: 275px;
  }
`;

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    order: 2;
  }
`;

const StyledItem = styled.li`
  margin: 0 50px 0 0;
  font-weight: 600;
  text-align: center;

  :last-of-type {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    ${withLineHoverEffect()};
  }

  @media (max-width: 1420px) {
    margin-right: 30px;
  }

  @media (max-width: 1024px) {
    margin: 0 0 30px 0;
    width: 100%;
    text-align: center;
  }
`;

const StyledAuthor = styled.span`
  font-weight: 500;

  a {
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    ${withLineHoverEffect()}
  }

  @media (max-width: 1024px) {
    order: 3;
  }
`;

const StyledCopyright = styled.span`
  @media (max-width: 1024px) {
    order: 4;
    margin-top: 30px;
  }
`;

const Footer = () => (
  <StyledWrapper data-scroll-section="">
    <StyledRow>
      <StyledLogo />
      <StyledList>
        {NAVIGATION_ITEMS.map(({ href, name }) => (
          <StyledItem key={href}>
            <ScrollTo href={href}>{name}</ScrollTo>
          </StyledItem>
        ))}
      </StyledList>
    </StyledRow>
    <StyledRow>
      <StyledCopyright>2022 &copy; BGK Investment</StyledCopyright>
      <StyledAuthor>
        Stworzone z ❤️ przez <a href="https://rend.pro/offer/developer-website">RendPro</a>
      </StyledAuthor>
    </StyledRow>
  </StyledWrapper>
);

export default Footer;
