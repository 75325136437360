import { css } from "styled-components";
import { cssEaseOutExpo } from "@theme/easings";

export const withLineHoverEffect = (color?: string, height?: string) => css`
  @media (min-width: 1025px) {
    position: relative;

    ::before {
      width: 100%;
      height: ${height || "2px"};
      background: ${({ theme }) => color || theme.foreground};
      position: absolute;
      content: "";
      bottom: -4px;
      transition: transform 0.5s ${cssEaseOutExpo};
      transform: scaleX(0);
    }

    :hover::before {
      transform: scaleX(1);
    }
  }
`;
