import React, { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import Logo from "@assets/svg/logo.svg";
import { NAVIGATION_ITEMS } from "@config/main";
import Button from "@components/atoms/Button/Button";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import { useScroll } from "@hooks/useScroll";
import { cssEaseOutExpo } from "@theme/easings";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { Slant as Hamburger } from "hamburger-react";

const StyledWrapper = styled.div<{ $isScrolled: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  background: #fff;
  display: flex;
  justify-content: center;
  z-index: 9999;
  will-change: height;
  transition: height 0.5s ${cssEaseOutExpo};

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      @media (min-width: 1025px) {
        height: 70px;
      }
    `};

  @media (min-width: 1025px) {
    .hamburger-react {
      display: none;
    }
  }

  @media (max-width: 580px) {
    height: 70px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(Logo)<{ $isScrolled: boolean }>`
  height: 60px;
  width: auto;
  transform-origin: center center;
  transition: transform 0.5s ${cssEaseOutExpo};

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      @media (min-width: 1025px) {
        transform: scale(0.9);
      }
    `};

  @media (min-width: 1025px) {
    @media (max-width: 1420px) {
      height: 50px;
    }

    @media (max-width: 1220px) {
      height: 35px;
      transform: scale(1) !important;
    }
  }

  @media (max-width: 580px) {
    height: 40px;
  }
`;

const StyledRightWrapper = styled.nav<{ $isActive: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    height: calc(var(--vh) * 100);
    width: 100%;
    background: ${({ theme }) => theme.foreground};
    padding-top: 90px;
    z-index: -1;
    justify-content: center;
    transform: translateY(-100%);
    transition: transform 1s ${cssEaseOutExpo};

    ${({ $isActive }) =>
      $isActive &&
      css`
        transform: translateY(0);
      `}
  }
`;

const StyledList = styled.ul`
  margin: 0 30px 0;
  padding: 0;
  list-style-type: none;
  display: flex;

  @media (max-width: 1220px) {
    margin: 0 20px 0;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1024px) {
    margin: 0 0 35px 0;
    width: 100%;
  }

  @media (max-width: 580px) {
    margin: 0 0 20px 0;
  }
`;

const StyledItem = styled.li<{ $isActive: boolean }>`
  font-weight: 600;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.primary : theme.washRegular};
  transition: color 0.5s ${cssEaseOutExpo};
  margin: 0 50px 0 0;

  :last-child {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
    display: block;

    ${({ $isActive, theme }) =>
      withLineHoverEffect($isActive ? theme.primary : theme.washRegular)};
  }

  @media (max-width: 1420px) {
    margin-right: 30px;
  }

  @media (max-width: 1220px) {
    margin-right: 15px;
  }

  @media (max-width: 1024px) {
    font-size: 2.4rem;
    margin: 0 0 40px 0;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 1.8rem;
    margin: 0;
    padding: 15px 0;
  }
`;

const StyledButton = styled(Button)`
  text-decoration: none;

  @media (max-width: 1024px) {
    font-size: 2.4rem;
    padding: 20px 35px;
    border-radius: 40px;
  }

  @media (max-width: 580px) {
    font-size: 1.8rem;
    padding: 15px 30px;
  }
`;

const Navigation = () => {
  const { scrollY, currentSection } = useScroll();
  const { background } = useTheme();
  const [isHamburgerActive, setHamburgerActive] = useState<boolean>(false);

  const buttonItem = NAVIGATION_ITEMS.find(({ asButton }) => asButton);
  const isScrolled = scrollY >= 50;

  const handleItemClick = () => {
    setHamburgerActive(false);
  };

  return (
    <StyledWrapper $isScrolled={isScrolled}>
      <StyledInnerWrapper>
        <ScrollTo href="/">
          <StyledLogo $isScrolled={isScrolled} />
        </ScrollTo>

        <StyledRightWrapper $isActive={isHamburgerActive}>
          <StyledList>
            {NAVIGATION_ITEMS.filter(({ asButton }) => !asButton).map(
              ({ name, href, onlyIndex }, index) => (
                <StyledItem key={href} $isActive={index === currentSection}>
                  <ScrollTo
                    href={href}
                    onlyIndex={onlyIndex}
                    onClick={handleItemClick}
                  >
                    {name}
                  </ScrollTo>
                </StyledItem>
              )
            )}
          </StyledList>

          <StyledButton
            forwardedAs={ScrollTo}
            href={buttonItem.href}
            onClick={handleItemClick}
          >
            {buttonItem.name}
          </StyledButton>
        </StyledRightWrapper>

        <Hamburger
          toggle={setHamburgerActive}
          toggled={isHamburgerActive}
          color={background}
          label={isHamburgerActive ? "Zamknij nawigację" : "Otwórz nawigację"}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default Navigation;
