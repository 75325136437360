import React, { useState } from "react";
import styled from "styled-components";
import Input from "@components/atoms/Input/Input";
import Button from "@components/atoms/Button/Button";
import Checkbox from "@components/atoms/Checkbox/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import ContactItem from "@components/atoms/ContactItem/ContactItem";
import {
  facebookIcon,
  instagramIcon,
  mailIcon,
  markerIcon,
  phoneIcon,
} from "@assets/icons";
import { Icon } from "@iconify/react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import { EMAIL_PATTERN } from "@components/molecules/Contact/patterns";

const StyledWrapper = styled.section`
  margin-top: 220px;
  background: ${({ theme }) => theme.backgroundSecondary};

  @media (max-width: 1024px) {
    margin-top: 120px;
  }

  @media (max-width: 580px) {
    margin-top: 80px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: auto;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
    padding-top: 260px;
  }

  @media (max-width: 427px) {
    padding-top: 300px;
  }

  @media (max-width: 382px) {
    padding-top: 320px;
  }
`;

const StyledLeftWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSocialMediaWrapper = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.washRegular};
  display: flex;
  align-items: center;
  margin-top: 150px;

  a:first-of-type {
    margin-left: 23px;
    margin-right: 15px;
  }

  svg {
    font-size: 2.4rem;
  }

  @media (max-width: 1024px) {
    margin-top: 50px;
  }

  @media (max-width: 580px) {
    font-size: 1.6rem;
  }
`;

const StyledHeader = styled.div`
  margin: 0 0 80px;

  @media (max-width: 1024px) {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledHeadline = styled.h2`
  font-weight: 700;
  font-size: 4.8rem;
  color: ${({ theme }) => theme.primary};
  margin: 0 0 25px;

  span {
    display: block;
  }

  @media (max-width: 1420px) {
    font-size: 3.4rem;
  }

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    margin: 0 0 20px;
  }
`;

const StyledParagraph = styled.p`
  font-size: 2rem;
  margin: 0;

  @media (max-width: 1420px) {
    font-size: 1.8rem;
  }

  @media (max-width: 580px) {
    text-align: center;
  }
`;

const StyledForm = styled.form`
  background: ${({ theme }) => theme.foreground};
  padding: 40px 50px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1420px) {
    padding: 20px 20px 30px;
  }

  @media (max-width: 1024px) {
    order: -1;
    width: 100%;
    max-width: 550px;
    margin-bottom: 60px;
  }
`;

const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  :first-of-type {
    margin-bottom: 30px;
  }

  @media (max-width: 1080px) {
    :first-of-type {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 580px) {
    flex-direction: column;

    :first-of-type {
      margin-bottom: 15px;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 250px;

  ${StyledFormRow} &:first-of-type {
    margin-right: 30px;
  }

  @media (max-width: 1420px) {
    width: 220px;

    ${StyledFormRow} &:first-of-type {
      margin-right: 20px;
    }
  }

  @media (max-width: 1080px) {
    width: 200px;

    ${StyledFormRow} &:first-of-type {
      margin-right: 10px;
    }
  }

  @media (max-width: 1024px) {
    width: 49%;

    ${StyledFormRow} &:first-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 580px) {
    width: 100%;

    ${StyledFormRow} &:first-of-type {
      margin-bottom: 15px;
    }
  }
`;

const StyledTextarea = styled(Input).attrs(() => ({
  forwardedAs: "textarea",
}))`
  margin-top: 35px;
  height: 170px;
  width: 100%;

  textarea {
    resize: none;
  }

  span {
    top: 25px;
  }

  @media (max-width: 1420px) {
    margin-top: 25px;
  }

  @media (max-width: 1080px) {
    margin-top: 15px;
  }
`;

const StyledReCaptchaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 25px auto 0 0;

  div {
    margin: 0 !important;
  }

  span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.error};
    margin-top: 10px;
    display: block;
  }

  > div {
    transform-origin: left top;
    transform: scale(0.9);
  }

  @media (max-width: 370px) {
    margin: 20px auto 0 0;
    max-width: 200px;

    > div {
      transform: scale(0.7);
    }
  }
`;

const StyledButton = styled(Button)`
  width: 360px;
  margin-top: 50px;

  @media (max-width: 1420px) {
    margin-top: 30px;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: auto;
  margin-top: 10px;

  @media (max-width: 370px) {
    margin-top: 0;
  }
`;

const AfterSendMessage = styled.div<{ $success: boolean; $error: boolean }>`
  color: ${({ theme, $success, $error }) =>
    ($success && theme.primary) || ($error && theme.error)};
  font-weight: 500;
  margin-top: 30px;
`;

const Contact = () => {
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [sendSuccessful, setSendSuccessful] = useState<boolean>();
  const [sendError, setSendError] = useState<boolean>();
  const [wasSendTry, setWasSendTry] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const {
    directus: { settings: cms },
  } = useStaticQuery<{
    directus: {
      settings: {
        phone_number: string;
        email: string;
        location: string;
        location_link: string;
        facebook_link: string;
        instagram_link: string;
      };
    };
  }>(query);

  const onSubmit: SubmitHandler<{
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email: string;
    message: string;
    consent: boolean;
  }> = (data) => {
    if (!captchaKey) {
      setWasSendTry(true);
      return;
    }

    if (sendSuccessful) {
      return;
    }

    setLoading(true);
    // prettier-ignore
    axios.post(`https://formsubmit.co/ajax/kontakt@bgkinvestment.pl`, {
      "Imię": data.firstName,
      "Nazwisko": data.lastName,
      "E-mail": data.email,
      "Numer telefonu": data.phoneNumber || "Nie podano",
      "Wiadomość": data.message,
    }).then((res) => {
      res.status === 200 ? setSendSuccessful(true) : setSendError(true)
    }).catch(() => setSendError(true)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <StyledWrapper data-scroll-section="" id="contact">
      <StyledInnerWrapper>
        <StyledLeftWrapper>
          <StyledHeader>
            <StyledHeadline>
              <span>Masz pytania?</span>
              <span>Skontaktuj się z nami!</span>
            </StyledHeadline>
            <StyledParagraph>
              Odpowiemy tak szybko jak to możliwe!
            </StyledParagraph>
          </StyledHeader>

          <ContactItem
            icon={phoneIcon}
            href={`tel:${cms.phone_number.replace(/\s/g, "")}`}
            content={cms.phone_number}
          />
          <ContactItem
            icon={mailIcon}
            href={`mailto:${cms.email}`}
            content={cms.email}
          />
          <ContactItem
            icon={markerIcon}
            href={cms.location_link}
            content={cms.location}
            iconSize="2.8rem"
          />

          <StyledSocialMediaWrapper>
            Obserwuj nas:{" "}
            <a href={cms.facebook_link} target="_blank" rel="noreferrer">
              <Icon icon={facebookIcon} />
            </a>
            <a href={cms.instagram_link} target="_blank" rel="noreferrer">
              <Icon icon={instagramIcon} />
            </a>
          </StyledSocialMediaWrapper>
        </StyledLeftWrapper>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledFormRow>
            <StyledInput
              placeholder="Imię"
              error={errors.firstName && "Imię jest wymagane"}
              {...register("firstName", { required: true })}
            />
            <StyledInput
              placeholder="Nazwisko"
              error={errors.lastName && "Nazwisko jest wymagane"}
              {...register("lastName", { required: true })}
            />
          </StyledFormRow>
          <StyledFormRow>
            <StyledInput
              placeholder="E-mail"
              error={
                errors.email?.type === "pattern"
                  ? "E-mail jest nieprawidłowy"
                  : errors.email?.type === "required" && "E-mail jest wymagany"
              }
              {...register("email", {
                required: true,
                pattern: EMAIL_PATTERN,
              })}
            />
            <StyledInput placeholder="Telefon" {...register("phoneNumber")} />
          </StyledFormRow>
          <StyledTextarea
            placeholder="Wiadomość"
            error={errors.message && "Napisz wiadomość"}
            {...register("message", { required: true })}
          />
          <StyledReCaptchaWrapper>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
              hl="pl"
              onChange={(key) => {
                setCaptchaKey(key);
              }}
            />
            {!captchaKey && wasSendTry && (
              <span>Potwierdź, że nie jesteś robotem</span>
            )}
          </StyledReCaptchaWrapper>
          <StyledCheckbox
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania."
            error={errors.consent ? "Zgoda jest wymagana." : undefined}
            {...register("consent", { required: true })}
          />
          <StyledButton
            isLoading={isLoading}
            isSuccessful={sendSuccessful}
            disabled={sendSuccessful}
          >
            Wyślij
          </StyledButton>

          {(sendSuccessful || sendError) && (
            <AfterSendMessage $success={sendSuccessful} $error={sendError}>
              {sendSuccessful && "Twoja wiadomośc została wysłana pomyślnie"}
              {sendError && "Wystąpił błąd. Prosimy spróbować ponownie później"}
            </AfterSendMessage>
          )}
        </StyledForm>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      settings {
        phone_number
        email
        location
        location_link
        facebook_link
        instagram_link
      }
    }
  }
`;

export default Contact;
