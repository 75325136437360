export const WEBSITE_TITLE = "BGK Investment";

export const NAVIGATION_ITEMS: {
  name: string;
  href: string;
  onlyIndex?: false;
  asButton?: boolean;
}[] = [
  { name: "Czym się zajmujemy?", href: "/#what_we_do" },
  { name: "O nas", href: "/#about_us" },
  { name: "Aktualnie w sprzedaży", href: "/#on_sale" },
  { name: "Inne inwestycje", href: "/#other_investments" },
  { name: "Kontakt", href: "#contact", asButton: true, onlyIndex: false },
];

export const Y_OFFSET = -140;
