import React, { forwardRef, HTMLProps, MouseEventHandler } from "react";
import { Link } from "gatsby";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { useLocation } from "react-use";
import { Y_OFFSET } from "@config/main";

const ScrollTo = forwardRef<HTMLAnchorElement, Props>(
  (
    { children, onClick: onClickProp, href, onlyIndex = true, ...props },
    ref
  ) => {
    const { scroll } = useLocomotiveScroll();
    const { pathname } = useLocation();

    const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault();
      scroll?.scrollTo(href === "/" ? 0 : href.replace("/", ""), {
        offset: Y_OFFSET,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onClickProp && onClickProp(e);
    };

    return (
      <>
        {pathname === "/" || !onlyIndex ? (
          <a onClick={onClick} ref={ref} href={href} {...(props as unknown)}>
            {children}
          </a>
        ) : (
          <Link to={href} ref={ref as any} {...(props as unknown)}>
            {children}
          </Link>
        )}
      </>
    );
  }
);

interface Props extends HTMLProps<HTMLAnchorElement> {
  onlyIndex?: false;
}

export default ScrollTo;
