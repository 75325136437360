import { useLocomotiveScroll } from "react-locomotive-scroll";
import { useEffect, useState } from "react";
import { NAVIGATION_ITEMS, Y_OFFSET } from "@config/main";

export const useScroll = () => {
  const { scroll } = useLocomotiveScroll();
  const [currentSection, setCurrentSection] = useState<number>(-1);
  const [scrollY, setScrollY] = useState<number>();

  useEffect(() => {
    scroll?.on("scroll", (e: any) => {
      const { y } = e.scroll;

      setScrollY(y);

      const positions = NAVIGATION_ITEMS.map(({ href }) => {
        const id = href.replace("/", "");
        const el = document.querySelector(id);

        if (!el) return 0;

        const rect = el.getBoundingClientRect();
        return rect.top + (y as number);
      });

      if (y < positions?.[0]) {
        setCurrentSection(-1);
      }

      positions?.forEach((pos, index) => {
        if (y - Y_OFFSET + 10 > pos) {
          setCurrentSection(index);
        }
      });
    });
  }, [scroll]);

  return { scrollY, currentSection };
};
