import React, { HTMLProps } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import checkIcon from "@iconify/icons-bi/check";

const StyledWrapper = styled.label`
  display: flex;
  cursor: pointer;
`;

const StyledInputWrapper = styled.div`
  margin-right: 10px;
  width: 25px;
  height: 25px;
  position: relative;
`;

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
`;

const StyledCheckbox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  border: 1px solid ${({ theme }) => theme.washRegular};

  ${StyledInput}:checked ~ & {
    background: ${({ theme }) => theme.primary};
  }
`;

const StyledLabel = styled.span`
  font-size: 1.2rem;
  max-width: 400px;
  color: ${({ theme }) => theme.washRegular};
  user-select: none;
`;

const StyledError = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.error};
  pointer-events: none;
  margin-top: 10px;
  display: block;
`;

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ label, className, error, ...props }, ref) => (
    <div className={className} {...props}>
      <StyledWrapper className={className}>
        <StyledInputWrapper>
          <StyledInput type="checkbox" ref={ref} {...(props as any)} />
          <StyledCheckbox>
            <Icon icon={checkIcon} />
          </StyledCheckbox>
        </StyledInputWrapper>
        <StyledLabel>{label}</StyledLabel>
      </StyledWrapper>
      {error && <StyledError>{error}</StyledError>}
    </div>
  )
);

interface Props extends HTMLProps<HTMLInputElement> {
  label: string;
  type?: "checkbox";
  error?: string;
}

export default Checkbox;
