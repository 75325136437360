import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  background: "#313237",
  backgroundSecondary: "#222223",
  foreground: "#fff",
  primary: "#54B4D4",
  washSuperHeavy: "#f8f7f9",
  washHeavy: "#8A8D93",
  washRegular: "#777c80",
  washLight: "#2C2D32",
  washLighter: "#1C242C",
  error: "#ff0033",
  errorBackground: "#ffe5e9",
};
