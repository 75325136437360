import React, { HTMLProps } from "react";
import styled, { css } from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledInnerWrapper = styled.label<{ $isError: boolean }>`
  color: ${({ theme }) => theme.washHeavy};
  background: ${({ theme }) => theme.washSuperHeavy};
  font-weight: 500;
  position: relative;
  padding: 15px 25px;
  border-radius: 30px;
  display: block;
  cursor: text;
  height: 100%;
  width: 100%;

  ${({ $isError }) =>
    $isError &&
    css`
      background: ${({ theme }) => theme.errorBackground};
      color: ${({ theme }) => theme.error};
    `}
`;

const StyledInput = styled.input`
  color: ${({ theme }) => theme.backgroundSecondary};
  font-weight: 500;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  outline: none;
`;

const StyledLabel = styled.span<{ $isError: boolean }>`
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
  display: block;
  pointer-events: none;

  ${StyledInput}:focus ~ &, ${StyledInput}:not(:placeholder-shown) ~ & {
    transform: translateY(-190%) scale(0.9);
  }

  ${({ $isError }) =>
    $isError &&
    css`
      color: ${({ theme }) => theme.error}!important;
    `}
`;

const StyledError = styled.div`
  font-size: 1.2rem;
  display: block;
  margin-top: 10px;
  pointer-events: none;
  user-select: none;
  color: ${({ theme }) => theme.error};
`;

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ placeholder, className, error, ...props }, ref) => (
    <StyledWrapper className={className}>
      <StyledInnerWrapper $isError={!!error}>
        <StyledInput placeholder=" " {...(props as any)} ref={ref} />
        <StyledLabel $isError={!!error}>{placeholder}</StyledLabel>
      </StyledInnerWrapper>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  )
);

interface Props extends HTMLProps<HTMLInputElement> {
  placeholder: string;
  error?: string;
}

export default Input;
